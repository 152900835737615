import styled from 'styled-components'
import Textarea from '@components/form/textarea'
import { MOBILE_BREAKPOINT } from 'src/constants'

export const DescriptionTitle = styled.span`
  color: black;
  margin-bottom: 8px;
  margin-left: 5px;
`

export const PopupDescription = styled(Textarea)`
  resize: none;
  padding: 10px;
  margin-top: 10px;
  height: 250px;
  align-self: center;
  @media (min-width: ${MOBILE_BREAKPOINT}) {
    width: 100%;
  }
`

export const FullWidthContainer = styled.div`
  width: 100%;
`
