import React from 'react'
import { connect } from 'react-redux'
import { getCurrentOrg } from '@selectors/org'
import RequestAcessDetailsTitle from '../requestAccessDetailsTitle'
import { Body2, Select, Stack, Subtitle2, TextArea as DSTextArea } from '@toriihq/design-system'
import { DescriptionTitle, PopupDescription, FullWidthContainer } from './styles'

interface RequestAccessDetailsContentProps {
  description: string
  onDescriptionChange: (value: string) => void
  supportedFeatures?: Record<string, any>
}

const RequestAccessDetailsContentComponent = ({
  description,
  onDescriptionChange,
  supportedFeatures
}: RequestAccessDetailsContentProps) => {
  if (supportedFeatures?.gartner_mq_2025_request_access) {
    return (
      <FullWidthContainer>
        <Stack gap='space-200'>
          <Body2>Please provide more details about your request:</Body2>
          <Stack gap='space-050'>
            <Subtitle2>License</Subtitle2>
            <Select
              options={[
                { label: 'Basic', value: 'basic' },
                { label: 'Pro', value: 'pro' },
                { label: 'Other', value: 'other' }
              ]}
              placeholder='Select a license'
            />
          </Stack>
          <Stack gap='space-050'>
            <Subtitle2>Duration</Subtitle2>
            <Select
              options={[
                { label: '6 days', value: '6d' },
                { label: '30 days', value: '30d' },
                { label: '6 months', value: '6m' },
                { label: 'Never', value: 'never' }
              ]}
              placeholder='Access will expire in...'
            />
          </Stack>
          <Stack gap='space-050'>
            <Subtitle2>Reason</Subtitle2>
            <DSTextArea
              value={description}
              onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => onDescriptionChange(e.target.value)}
            />
          </Stack>
        </Stack>
      </FullWidthContainer>
    )
  }

  return (
    <>
      <DescriptionTitle><RequestAcessDetailsTitle /></DescriptionTitle>
      <PopupDescription
        value={description}
        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => onDescriptionChange(e.target.value)}
        border={false}
      />
    </>
  )
}

const mapStateToProps = (state: any) => {
  const { supportedFeatures } = getCurrentOrg(state) || {}
  return { supportedFeatures }
}

export const RequestAccessDetailsContent = connect(mapStateToProps)(RequestAccessDetailsContentComponent)
